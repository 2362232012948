import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Load Styles
import '../scss/main.scss';


// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// require('bootstrap-icons/font/bootstrap-icons.css');
import '../../node_modules/@fortawesome/fontawesome-free/css/brands.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/regular.css';
import '../../node_modules/@fortawesome/fontawesome-free/css/solid.css';
import '../../node_modules/@fortawesome/fontawesome-free/js/all.js';
// import FastGlob from 'fast-glob';

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
// console.log(`Hello ${process.env.HELLO}`);

$(document).ready(function() {

 var formOpenerBtn = $('.btn--show-survery-form');
 formOpenerBtn.on('click', function(e) {
  e.preventDefault();
  $('#preloader').addClass('d-flex');
  $('.hero-section').addClass('hero-section--hidden');
  $('.survey-form-section').addClass('survey-form-section--active');
  setTimeout(function() {
    $('#preloader').removeClass('d-flex');
  },600)
 })


 // Survey Form Function 
var dataObj = {
  sf_q1_ans : '',
  sf_q2_ans: '',
  sf_q3_ans: '',
  sf_q4_ans: '',
  sf_q5_ans: '',
  sf_q6_ans: '',
  sf_q7_ans: '',
  sf_q8_ans: '',
  sf_q9_ans: '',
  sf_q10_ans: '',
  sf_q11_ans: ''
}

 var surveyFormEl = $('.survery-form');
 var nextBtns = $('.survey-form__btn--next');
 var submitBtn = $('.survey-form__btn--submit');

 var formFields = $('.survey-form .form__field');
 var progressBar = $('.progress .progress-bar');
 var progressBarValue = $('.progress-value');
 var progressStep = 100 / formFields.length;
 progressBar.css('width', progressStep);
 progressBarValue.text(`1 out of ${formFields.length}`);

 nextBtns.each(function() {
  $(this).on('click', function(e) {
    e.preventDefault();
    var fieldStep = $(this).closest('.form__field').attr('data-step');
    var getInputFieldName = $(this).closest('.form__field').find('input').attr('name');
    var getInputVal = $(this).closest('.form__field').find('input').val();
    var getParent = $(this).closest('.form__field');
    // console.log(getInputVal);
    var checkValidation = checkRequired(getParent);

    if(checkValidation) {
      // $(this).closest('.form__field').find('.form__field--error').hide();
      // $(this).closest('.form__field').next().addClass('form__field--active');
      // $(this).closest('.form__field').removeClass('form__field--active');
      showNextStep(getParent);
    }
    else {
      // $(this).closest('.form__field').find('.form__field--error').show();
      displayError(getParent);
    }
  })
 })

var radioBoxes = $('.survey-form input[type="radio"]');
radioBoxes.each(function() {
  $(this).on('click', function() {
    $(this).closest('.form__field').find('input[type="radio"]').attr('checked_input', false);
    $(this).attr('checked_input', true);
  })
})

 // Checking required function
 function checkRequired(parent) {
  var hasCheckedCounter = 0;
  var getInputs = parent.find('input[type="radio"');
  // console.log(getInputs);

  getInputs.each(function(){
    if($(this).attr('checked_input') == 'true') {
      hasCheckedCounter++;
      var filledName = $(this).attr('name');
      var filledVal = $(this).val();
      // localStorage.setItem(filledName, filledVal);
      dataObj[filledName] = filledVal;
      localStorage.setItem('dataContent', JSON.stringify(dataObj));
    }
  })
  if(hasCheckedCounter > 0) {
    hasCheckedCounter = 0;
    return true;
  }
  else {
    return false;
  }
}

// Show next step
function showNextStep(parent) {
  $('#preloader').addClass('d-flex');
  setTimeout(function() {
    $('#preloader').removeClass('d-flex');
    $('.form__field--error').hide();
    parent.next().addClass('form__field--active');
    parent.removeClass('form__field--active');
    updateProgressbar(parent);
  },600)
}

function displayError(parent) {
  parent.find('.form__field--error').show();
}

function updateProgressbar(parent) {
  var updateVal = 0;
  var nextStepIndex = 0;
  var getIndex = parent.attr('data-step');
  // getIndex = parseInt(getIndex);
  // console.log(getIndex, typeof(getIndex));
  getIndex = parseInt(getIndex);
  
  updateVal = (progressStep * (getIndex + 1));
  // console.log(updateVal);
  progressBar.css('width', updateVal + '%');
  nextStepIndex = parent.attr('data-step');
  nextStepIndex = parseInt(nextStepIndex);
  nextStepIndex = nextStepIndex + 1;
  console.log(nextStepIndex);
  progressBarValue.text(`${nextStepIndex} out of ${formFields.length}`);
}

submitBtn.on('click' , function(e) {
  e.preventDefault();
  var getParent = $(this).closest('.form__field');

  var checkValidation = checkRequired(getParent);
  if(checkValidation) {
    $('#preloader').addClass('d-flex');
    setTimeout(function(){
      $('.survey-form-section').hide();
    },500)

    setTimeout(function() {
      $('#preloader').removeClass('d-flex');
      $('.form__field--error').hide();
      window.open('thank-you.html', '_self');
    },1500)
    // console.log(dataObj);
    var dataContent = JSON.parse(localStorage.getItem('dataContent'));
    console.log(dataContent);
    localStorage.clear();
  }
  else {
    displayError(getParent);
  }
})

});


